const se = {
	USPs: {
		freemium: [
			{ text: 'Full access', active: false },
			{ text: 'Staccs-TV', active: true },
			{ text: 'Setlist', active: false },
			{ text: 'Discovery', active: true },
			{ text: 'Inget kreditkort krävs', active: true },
		],
		threeDaySub: [
			{ text: 'Full access', active: true },
			{ text: 'Staccs-TV', active: true },
			{ text: 'Setlist', active: true },
			{ text: 'Discovery', active: true },
			{ text: 'Exklusiv rabatt', active: true },
		],
		monthlySub: [
			{ text: 'Full access', active: true },
			{ text: 'Staccs-TV', active: true },
			{ text: 'Setlist', active: true },
			{ text: 'Discovery', active: true },
			{ text: 'Avsluta när du vill', active: true },
		],
		yearlySub: [
			{ text: 'Full access', active: true },
			{ text: 'Staccs-TV', active: true },
			{ text: 'Setlist', active: true },
			{ text: 'Discovery', active: true },
			{ text: 'Avsluta när du vill', active: true },
		],
	},
	signUpDiscovery: {
		startFree: {
			heading: 'Bli medlem idag!',
			subHeading: 'Första 12 månaderna gratis',
		},
		upgradePremium: {
			heading: 'Uppgradera till premium!',
			subHeading: 'Med premium får du:',
			cardDetails: 'Kortuppgifter',
		},
	},
	yourCurrentMembership: 'Ditt nuvarande medlemskap',
	reset: 'Återställ',
	resetPassword: 'Återställ lösenord',
	forgotPassword: 'Glömt lösenord?',
	fullAccess: 'Du har redan full tillgång, njut av en konsert!',
	upgradeHeadline:
		"VÅRA <span className='pricing-heading--span'>MEDLEMSKAP.</span",
	upgradeSubheading: 'Uppgradera för en större upplevelse',
	becomeMember: 'Bli medlem',
	tellMeMore: 'Berätta mer',
	account: 'Konto',
	signIn: 'Logga in',
	signOut: 'Logga ut',
	signUp: 'Bli medlem',
	backToApp: 'Tillbaka till app',
	subscription: 'Abonnemang',
	amount: 'Kostnad',
	alreadyMember: 'Redan medlem?',
	validUntil: 'Gäller till',
	trailUntil: 'Testperiod till',
	creditCard: 'Du har inget kreditkort registrerat.',
	updateProfile: 'Uppdatera profil',
	ctaButton: 'Välj',
	heroHeadings: {
		heroOne:
			"<p>Upplevde</p><p> precis</p> <div><p class='hero__artist'>Queen</p><p>&nbspfrån</p></div><p>Wembley.</p><p class='hero__copy--cursive'>First row.</p>",
		heroTwo:
			"Jag såg <p>Tupac och</p> <p class='hero__artist'>DAVID Ritschard</p> <p> live, samma dag.</p>",
		heroThree:
			"Fick äntligen se <span class='hero__artist--span'>Coldplay</span> live igen, i sovrummet.",
	},
	newHero: {
		newHeroHeading1: 'Konsertupplevelser.',
		newHeroHeading2: 'Var som helst, när som helst.',
		newHeroCopy:
			'Fördjupa dig i världen av live-musik. Upptäck och återupplev dina favoritkonserter när du vill, var du än är. Första parkett väntar.',
		newHeroCTA: 'Upptäck' + ' ' + ' ',
	},

	discover: 'Upptäck Staccs',
	freeTrialDays: 'dagars provperiod',
	headline:
		"STREAMA DINA <span className='pricing-heading--span'>FAVORITKONSERTER.</span>",
	subHeadline:
		"Bli medlem <span className='subheading--span'>gratis</span> idag och utforska bland hundratals konserter.",
	bigScreen: 'Njut av Staccs på stora skärmen',
	bigScreenSub:
		'Stora upplevelser kräver stora skärmar! Njut av Staccs via vår AppleTV-app eller Casta från din telefon via Airplay eller Chromecast, direkt upp på den stora skärmen.',
	setlist: 'Setlist',
	setlistSub:
		'Skapa en setlist baserat på dina favoritlåtar. Road trip, förfest, träningspass eller lugn kväll i soffan – bygg setlists för alla tillfällen. Setlist ger dig som användare möjlighet att utforska och upptäcka nya favoritlåtar bland Staccs egna kurerade setlists. ',
	originals: 'Exklusivt bara för dig på Staccs',
	originalsSub:
		'Förutom alla legendariska konserter skapar Staccs Originals hela tiden nytt och exklusivt musikinnehåll - konserter, behind the scenes eller dokumentärer. Allt det där du inte kommer åt via en konsertbiljett.',
	userCentric: 'User centric',
	userCentricSub:
		'Stöd dina favoritartister direkt! Tack vare vår User Centric-modell går 2/3 av de intäkter du genererar via ditt abonnemang direkt till de artister du streamar. ',
	FAQs: {
		heading: 'VANLIGA FRÅGOR OCH SVAR',
		questions: [
			{
				question: 'Vad är Staccs?',
				answer:
					'Staccs är en streamingtjänst med konserter och musikdokumentärer. Upplev allt från konserter, klubbspelningar och unika tv-framträdanden till fängslande dokumentärer - med både legendariska artister och morgondagens stjärnor. Nytt innehåll tillkommer varje vecka.',
			},
			{
				question: 'Hur mycket kostar Staccs?',
				answer: `Det finns tre olika medlemskap att välja mellan: Medlem, Månad och År.<br> <a href="#pricing-anchor">Klicka här</a> för att se priserna på våra medlemskap.`,
			},
			{
				question: 'Var tittar jag på Staccs?',
				answer:
					'Titta var och när du vill, Staccs är tillgängligt på alla enheter. Logga in på ditt Staccs-konto för att titta direkt på webben på app.staccs.com eller på enheter som har Staccs-appen. Du kan titta på Staccs på mobil, surfplatta eller på din TV via AppleTV, Chromecast och AirPlay.',
			},
			{
				question: 'Hur avslutar jag tjänsten?',
				answer:
					'Vill du avsluta ditt abonnemang gör du det enkelt genom din profil. Abonnemanget avslutas när din aktuella faktureringsperiod tar slut.',
			},
		],
	},
	footerCompany: 'Företaget',
	footerCompany1: 'Om Staccs',
	footerCompany2: 'Villkor',
	footerCompany3: 'Integritet',
	footerCompany4: 'Lediga tjänster',
	footerUseFull: 'Användbara länkar',
	footerUseFull1: 'Support',
	footerConnect: 'Sociala medier',
	footerDownload: 'Ladda ned Staccs',
	footerDownload1: 'Ladda ner vår app!',
	name: 'Namn',
	email: 'E-post',
	password: 'Lösenord',
	yearly: 'årsvis',
	monthly: 'månadsvis',
	streamNow: 'Streama',
	acceptTerms:
		'Jag godkänner <a href="/terms">Allmänna Villkor</a> och <a href="/privacy">Integritetspolicy</a>',
	createAccountButton: 'Skapa konto',
	profile: 'Profil',
	accountDetails: 'Kontoinformation',
	memberSince: 'Medlem sen',
	step: 'Steg',
	emailconfirm: 'Bekräfta E-post',
	passwordconfirm: 'Bekräfta Lösenord',
	nextButton: 'Nästa',
	pickPlan: 'Välj abonnemang',
	completeRegistration: 'Slutför registreringen',
	step3Headline: 'Betalning',
	step3year: 'Starta ditt årsabonnemang för ',
	step3month: 'Starta ditt månadsabonnemang för ',
	step3hours: 'Starta ditt 72 timmars pass för ',
	step3row2: 'Lägg till dina betalningsuppgifter för att komma igång',
	step3row3: 'Första betalning kommer ske efter din ',
	step3row3_2: '-dagars fria provperiod, och därefter ',
	step3row4:
		'Du kan säga upp ditt abonnemang när du vill. När detta steg är klart kommer du få en bekräftelse via e-post till ',
	step4Headline: 'VÄLKOMMEN TILL STACCS',
	step4row1: 'Grattis - ditt konto är nu redo att användas.',
	step4row2:
		'Börja använda Staccs genom att logga in på <a target="_blank" href="https://app.staccs.com">app.staccs.com</a> eller ladda ner någon av våra appar för AppleTV, iPad, iOS eller Android.',
	step4row3: 'Happy streaming,',
	aboutHeadline1: 'Av fans, för fans - alltid på första parkett',
	aboutHeadline2: 'Från klassiska arenakonserter till framtidens musik-TV',
	aboutHeadline3: 'En mer rättvis affärsmodell',
	about1:
		'Staccs är en Stockholmsbaserad streamingtjänst skapad av och för musikentusiaster, som erbjuder en stor katalog av hundratals livekonserter och musikdokumentärer. Vår ambition är att kunna erbjuda alla musikälskare bästa tänkbara utbud, oavsett tidsera eller genre, och vi letar konstant efter nytt innehåll att bredda vår tjänst med.',
	about2:
		'Vårt mål är att ta den streamade konsertupplevelsen till nästa nivå. Oavsett om det är klassiska arenakonserter, legendariska klubbgig, unika TV-framträdanden eller djupgående dokumentärer, så lägger vi största vikt vid att upplevelsen ska bli den bästa tänkbara såväl ljud- som bildmässigt. Vi vill skapa den bästa och ledande streamingtjänsten inom alla musikkategorier - konserter, dokumentärer, nyproducerat och exklusivt innehåll för Staccs, nya TV-format och mycket mer.',
	about3:
		'Vi bygger Staccs tillsammans med fans och artister - tack vare vår User Centric-modell fördelas streamingintäkterna till artister och rättighetsinnehavare baserat på vad du som användare tittar på. Detta menar vi är en mer rättvis modell och gör att du som fan kan känna dig trygg i att det du streamar gynnar dina favoritartister.',
};

const en = {
	USPs: {
		freemium: [
			{ text: 'Full access', active: false },
			{ text: 'Staccs-TV', active: true },
			{ text: 'Setlist', active: false },
			{ text: 'Discovery', active: true },
			{ text: 'No card required', active: true },
		],
		threeDaySub: [
			{ text: 'Full access', active: true },
			{ text: 'Staccs-TV', active: true },
			{ text: 'Setlist', active: true },
			{ text: 'Discovery', active: true },
			{ text: 'Exclusive discount', active: true },
		],
		monthlySub: [
			{ text: 'Full access', active: true },
			{ text: 'Staccs-TV', active: true },
			{ text: 'Setlist', active: true },
			{ text: 'Discovery', active: true },
			{ text: 'Cancel anytime', active: true },
		],
		yearlySub: [
			{ text: 'Full access', active: true },
			{ text: 'Staccs-TV', active: true },
			{ text: 'Setlist', active: true },
			{ text: 'Discovery', active: true },
			{ text: 'Cancel anytime', active: true },
		],
	},
	signUpDiscovery: {
		startFree: {
			heading: 'Become a member today!',
			subHeading: 'First 12 months for free',
		},
		upgradePremium: {
			heading: 'Upgrade to premium!',
			subHeading: 'Premium gives you:',
			cardDetails: 'Card details',
		},
	},
	yourCurrentMembership: 'Your current membership',
	reset: 'Reset',
	resetPassword: 'Reset password',
	forgotPassword: 'Forgot password?',
	fullAccess: 'You already have full access, go enjoy a concert!',
	upgradeHeadline:
		"OUR <span className='pricing-heading--span'>MEMBERSHIPS.<span>",
	upgradeSubheading: 'Upgrade for a bigger experience.',
	becomeMember: 'Become member',
	tellMeMore: 'Tell me more',
	account: 'Account',
	signIn: 'Sign in',
	signOut: 'Sign out',
	signUp: 'Sign up',
	backToApp: 'Back to app',
	subscription: 'Subscription',
	amount: 'Amount',
	alreadyMember: 'Already member?',
	validUntil: 'Valid until',
	trailUntil: 'Trial until',
	creditCard: 'You have no credit card registered.',
	updateProfile: 'Update profile',
	ctaButton: 'Select',
	heroHeadings: {
		heroOne:
			'Just <p>experienced</p> <span class="hero__artist--span">Queen </span>live <p>from Wembley.</p> <p class="hero__copy--cursive"> First row.</p>',
		heroTwo:
			'I saw <p>Tupac and </p><p class="hero__artist">David Ritschard</p> live, all in the <p>same night.</p>',
		heroThree:
			'Finally got to <p>see <span class="hero__artist--span">Coldplay</span></p> live again, <p>FROM MY bedroom.</p>',
	},
	newHero: {
		newHeroHeading1: 'The Concert Experience.',
		newHeroHeading2: 'Anywhere, anytime.',
		newHeroCopy:
			'Immerse yourself in the world of live music. Discover and relive your favorite concerts on demand, wherever you are. Your front-row seat awaits.',
		newHeroCTA: 'Enter',
	},

	discover: 'Discover Staccs',
	freeTrialDays: 'days free trial',
	headline:
		"STREAM YOUR <span className='pricing-heading--span'>FAVORITE CONCERTS.</span>",
	subHeadline:
		"Become a member for <span className='subheading--span'>free</span> today and explore hundreds of concerts.",
	bigScreen: 'Best Experienced on a Big Screen',
	bigScreenSub:
		'Take your Staccs experience to new heights by streaming all concerts on a larger display. Enjoy Staccs on Apple TV, or effortlessly cast from your phone using AirPlay or Chromecast to your preferred screen.',
	setlist: 'Setlists',
	setlistSub:
		'Customize your own playlists for any occasion, from road trips, dinner parties, workouts or study sessions. Explore curated setlists and build your unique collections with tracks from your favourite concerts.',
	originals: 'Staccs Exclusives',
	originalsSub:
		'Uncover unique concerts, music documentaries, and Staccs Originals, available only on Staccs. Elevate your concert experience with front-row access that goes beyond the stage.',
	userCentric: 'User centric',
	userCentricSub:
		// 'Support your favorite artists directly! Thanks to our user centric model, 2/3 of your subscription fee is distributed between the artists you stream.',
		'We build Staccs together with fans and artists. Through our user-centric model, we give two thirds of what you pay for your subscription directly to the artists that you stream. We believe this is a fairer model and enables you as a fan to be confident that what you stream benefits your favourite artists.',
	FAQs: {
		heading: 'Frequently asked questions',
		questions: [
			{
				question: 'What is Staccs?',
				answer:
					'Staccs is a streaming service with concerts and music documentaries. Enjoy everything from concerts, club shows and unique TV performances to captivating documentaries - with legendary artists and the stars of tomorrow. New shows are added every week.',
			},
			{
				question: 'What is the cost of a Staccs subscription?',
				answer: `There are four different plans to choose from: Member, Monthly and Yearly.<br> <a href="#pricing-anchor">Click here</a> to see the price of each subscription.`,
			},
			{
				question: 'How can I watch Staccs?',
				answer:
					'Watch anywhere and anytime, Staccs is available on all devices. Log in to your Staccs account and start watching at staccs.com or on any device with the Staccs app. You can watch Staccs on mobile, iPad or on your TV via AppleTV, Chromecast or AirPlay',
			},
			{
				question: 'How do I cancel my subscription?',
				answer:
					'If you want to cancel your subscription you can easily do that from your profile. The subscription is cancelled when your current payment period ends.',
			},
		],
	},
	footerCompany: 'The company',
	footerCompany1: 'About Staccs',
	footerCompany2: 'Terms',
	footerCompany3: 'Integrity',
	footerCompany4: 'Work at Staccs',
	footerUseFull: 'Usable links',
	footerUseFull1: 'Support',
	footerConnect: 'Social media',
	footerDownload: 'Download Staccs',
	footerDownload1: 'Download our app!',
	name: 'Name',
	email: 'Email',
	password: 'Password',
	yearly: 'yearly',
	monthly: 'monthly',
	streamNow: 'Stream now',
	acceptTerms:
		'I accept the <a href="/terms">Terms & Conditions</a> and <a href="/privacy">Privacy Policy</a>',
	createAccountButton: 'Create account',
	profile: 'Profile',
	accountDetails: 'Account information',
	memberSince: 'Member since',
	step: 'Step',
	emailconfirm: 'Confirm Email',
	passwordconfirm: 'Confirm Password',
	nextButton: 'Next',
	pickPlan: 'Pick plan',
	completeRegistration: 'Complete registration',
	step3Headline: 'Payment ',
	step3year: 'Start your annual subscription ',
	step3month: 'Start your monthly subscription for ',
	step3hours: 'Start your 72 hour pass for ',
	step3row2: 'Add your payment details to to create your account ',
	step3row3: 'Your first payment will be charged after your ',
	step3row3_2: ' day free trial period, and thereafter ',
	step3row4:
		'You can cancel your subscription whenever you want. When this step is completed you will receive a confirmation email to ',
	step4Headline: 'WELCOME TO STACCS',
	step4row1: 'Congrats - your account is now ready to use.',
	step4row2:
		'Start using Staccs by logging in to your account on <a target="_blank" href="https://app.staccs.com">app.staccs.com</a> or download any of our apps for AppleTV, iPad, iOS or Android.',
	step4row3: 'Happy streaming',
	aboutHeadline1: 'By fans, for fans - always in the front row',
	aboutHeadline2:
		'From legendary stadium concerts to the music TV of the future',
	aboutHeadline3: 'A fair business model',
	about1:
		'Staccs is a Stockholm based streaming service created by and for music enthusiasts, offering a big catalogue of hundreds of live concerts and music documentaries. Our ambition is to be able to offer all music lovers the best possible selection, regardless of era or genre, and we are constantly looking for new content to broaden our service with.',
	about2:
		'Our goal is to take the streamed concert experience to the next level. Whether it is classic stadium concerts, legendary club gigs, unique TV appearances or in-depth documentaries, for us it’s important that the experience is the best imaginable both in terms of sound and image. We want to create the best and leading streaming service in all music categories - concerts, documentaries, newly produced and exclusive content for Staccs, new TV formats and much more.',
	about3:
		'We build Staccs together with fans and artists - thanks to our User Centric model, the streaming revenue is distributed to artists and rights holders based on what you as a user is watching. We believe this is a fairer model and enables you as a fan to be confident that what you stream benefits your favourite artists.',
};

export { se, en };
