import React, { useState } from 'react';

import axios from 'axios';
import { api_url } from '../Constants/constants';

export const AuthContext = React.createContext();

export const AuthProvider = (props) => {
	const [token, setToken] = useState();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [email, setEmail] = useState('');
	const [profileObj, setProfileObj] = useState('');
	const updateToken = (token) => {
		axios.defaults.headers.common['x-auth-token'] = token;
		localStorage.setItem('token', token);
	};

	const login = (string) => {
		updateToken(string);
		setIsAuthenticated(true);
	};

	const logout = () => {
		updateToken('');
		setIsAuthenticated(false);
		localStorage.removeItem('user');
		localStorage.removeItem('token');
	};

	// check auth.

	const checkToken = async () => {
		const currentToken = localStorage.getItem('token');
		if (currentToken) {
			axios.defaults.headers.common['x-auth-token'] = currentToken;
			try {
				const res = await axios.get(`${api_url}/api/auth/`);

				if (res?.data?.email) {
					// console.log("localstoragelocalstoragelocalstorage", res.data);
					// setProfileObj(res.data)
					// setEmail(res?.data?.email);
					// login(currentToken);

					login(currentToken);
					console.log(profileObj);
				}
			} catch (error) {
				logout();
			}
		}
	};
	if (!isAuthenticated) {
		checkToken();
	}

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				token,
				login,
				logout,
				email,
				setProfileObj,
				profileObj,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};
