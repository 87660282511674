// DEPENDENCIES
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

// CONTEXT
import { LanguageProvider } from './Context/language';
import { RegisterProvider } from './Context/RegisterContext';
import { ArtistCollabProvider } from './Context/artistCollabContext';
import { DialogProvider } from './Context/DialogContext';
import { FilterProvider } from './Context/FilterContext';
import { TempAuthProvider } from './Context/tempAuthContext';
import { HeaderProvider } from './Context/HeaderContext';
import { UpgradeProvider } from './Context/UpgradeContext';
import { VideoControllerProvider } from './Context/videoContext';
import { AuthProvider } from './Context/AuthContext';
import { PlansProvider } from './Context/PlansContext';

// ROUTER
import Router from './Router';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<BrowserRouter>
		<LanguageProvider>
			<RegisterProvider>
				<PlansProvider>
					<ArtistCollabProvider>
						<FilterProvider>
							<DialogProvider>
								<TempAuthProvider>
									<HeaderProvider>
										<UpgradeProvider>
											<VideoControllerProvider>
												<AuthProvider>
													<Router />
												</AuthProvider>
											</VideoControllerProvider>
										</UpgradeProvider>
									</HeaderProvider>
								</TempAuthProvider>
							</DialogProvider>
						</FilterProvider>
					</ArtistCollabProvider>
				</PlansProvider>
			</RegisterProvider>
		</LanguageProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
