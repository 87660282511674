import { useState, createContext } from 'react'

export const UpgradeContext = createContext()

export const UpgradeProvider = props => {
  const [priceId, setPriceId] = useState(null)
  const [recurring, setRecurring] = useState(false)
  const [stripeCustomerId, setStripeCustomerId] = useState(null)
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState(null)
  const [setupConfirmed, setSetupConfirmed] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState(null)

  return (
    <UpgradeContext.Provider
      value={{
        priceId,
        setPriceId,
        recurring,
        setRecurring,
        stripeCustomerId,
        setStripeCustomerId,
        paymentIntentClientSecret,
        setPaymentIntentClientSecret,
        setupConfirmed,
        setSetupConfirmed,
        paymentMethodId,
        setPaymentMethodId,
      }}
    >
      {props.children}
    </UpgradeContext.Provider>
  )
}
