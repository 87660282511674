import React, { useEffect, useState } from 'react';

export const DialogContext = React.createContext();

export const DialogProvider = (props) => {
	const [signupDialogOpen, toggleSignupDialogOpen] = useState(false);
	const [view, setView] = useState('REGISTERFREE');
	const [giftLoading, setGiftLoading] = useState(false);
	const [gift, setGift] = useState({});
	const [selectedTitleId, setSelectedTitleId] = useState('');
	const [subtitle, setSubtitle] = useState('');
	const [partnerTitle, setPartnerTitle] = useState('');
	const [partnerName, setPartnerName] = useState('');

	const setSignupDialogOpen = (bool, viewString, titleId) => {
		toggleSignupDialogOpen(bool);
		if (viewString) {
			setView(viewString);
		}
		if (titleId) {
			setSelectedTitleId(titleId);
		}
	};

	return (
		<DialogContext.Provider
			value={{
				partnerName,
				setPartnerName,
				partnerTitle,
				setPartnerTitle,
				subtitle,
				setSubtitle,
				signupDialogOpen,
				toggleSignupDialogOpen,
				setSignupDialogOpen,
				view,
				setView,
				selectedTitleId,
				setSelectedTitleId,
				giftLoading,
				setGiftLoading,
				gift,
				setGift,
			}}
		>
			{props.children}
		</DialogContext.Provider>
	);
};
