import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { RegisterContext } from '../../../../../Context/RegisterContext';
import { LanguageContext } from '../../../../../Context/language';
import { api_url } from '../../../../../Constants/constants';
import { GoogleLogin } from '../../../../../Utils/helpers/GoogleOauth2';
import axios from 'axios';
import { TempAuthContext } from '../../../../../Context/tempAuthContext';

function RegisterPremiumStepOne({ view, setView }) {
	const { language } = useContext(LanguageContext);
	const {
		formData,
		setFormData,
		termsAccepted,
		setTermsAccepted,
		createGoogleUser,
	} = useContext(RegisterContext);
	const { name, email, password } = formData;
	const { setAccessToken, setRefreshToken, setOldToken } =
		useContext(TempAuthContext);
	const [formError, setFormError] = useState(null);
	const [disableButton, setDisableButton] = useState(false);
	const locale = window.localStorage.getItem('locale');

	useEffect(() => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'premium_signup_modal',
		});
	}, []);

	const onClickHandlerGoogle = async () => {
		setDisableButton(true);
		var config = {
			method: 'post',
			url: `${api_url}/api/auth/google-create`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: {
				name: formData.name,
				email: formData.email,
				country: locale
			},
		};

		const res = await axios(config);

		if (res.status === 201) {
			setAccessToken(res.data.tokens.accessToken);
			setRefreshToken(res.data.tokens.refreshToken);
			setOldToken(res.data.tokens.oldToken);
			setFormData({
				...formData,
				customerId: res.data.userId,
				stripeId: res.data.stripeId,
			});
			setView('REGISTERPREMIUMSTEPTWO');
		} else {
			setDisableButton(false);
		}
	};

	const onClickHandler = async () => {
		setDisableButton(true);
		setFormError(null);

		const response = await fetch(`${api_url}/api/signup/createaccount`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ ...formData, paymentType: 'stripe', country: locale }),
		});

		const data = await response.json();

		if (response.status === 400) {
			if (data.errors) {
				data.errors[0].param === 'email' && setFormError('Invalid email');
				data.errors[0].param === 'password' &&
					setFormError('Password has to be more than 8 characters');
			}

			switch (data.msg) {
				case 'User already exists':
					setFormError(data.msg);
					return;
				case 'Username is too short':
					setFormError(data.msg);
					return;
				case 'Password is too short':
					setFormError(data.msg);
					setFormData({ ...formData, password: '' });
					return;
				default:
					break;
			}
			setDisableButton(false);
			return;
		}

		const userId = await data.userId;
		const stripeId = await data.stripeId;

		setFormData({
			...formData,
			customerId: userId,
			stripeId: stripeId,
		});

		if (!data.error) {
			setView('REGISTERPREMIUMSTEPTWO');
		}
	};

	const handleSignIn = () => {
		setView('SIGNIN');
	};

	const onInputChange = (event) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<h2
				style={{
					fontWeight: 'bold',
					fontSize: '18px',
					lineHeight: '25px',
					textAlign: 'center',
					marginBottom: '20px',
				}}
			>
				{locale === 'se'
					? 'Bli premiumkund idag och se hundratals konserter'
					: 'Create a premium account today and watch hundreds of concerts'}
			</h2>
			<h3
				style={{
					fontSize: '12px',
					textAlign: 'center',
					paddingBottom: '1rem',
				}}
			>
				{language.step} 1 / 3
			</h3>

			<div>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
					{!createGoogleUser && (
						<>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									maxWidth: '300px',
									width: '100%',
									margin: 'auto',
								}}
							>
								<GoogleLogin />
							</div>
							<span className="registration__form-divider">or</span>
						</>
					)}
				</div>

				<div
					style={{
						gap: '15px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<TextField
						className="outlined-basic"
						label="Name"
						name="name"
						variant="outlined"
						value={name}
						style={{ maxWidth: '300px', width: '100%' }}
						InputLabelProps={{ style: { fontSize: '12px' } }}
						InputProps={{ style: { fontSize: '12px' } }}
						onChange={(e) => onInputChange(e)}
						autoFocus={true}
					/>

					<TextField
						className="outlined-basic"
						label="Email"
						type="email"
						name="email"
						value={email}
						variant="outlined"
						InputLabelProps={{ style: { fontSize: '12px' } }}
						InputProps={{ style: { fontSize: '12px' } }}
						style={{
							maxWidth: '300px',
							width: '100%',
						}}
						onChange={(e) => onInputChange(e)}
					/>

					{!createGoogleUser && (
						<TextField
							className="outlined-basic"
							label="Password"
							name="password"
							value={password}
							variant="outlined"
							InputLabelProps={{ style: { fontSize: '12px' } }}
							InputProps={{ style: { fontSize: '12px' } }}
							style={{ maxWidth: '300px', width: '100%' }}
							type="password"
							onChange={(e) => onInputChange(e)}
						/>
					)}
					<div className={'registration__terms-wrapper'}>
						<div
							role={'checkbox'}
							tabIndex="0"
							className="registration__terms-checkbox"
							onClick={(e) => {
								setTermsAccepted(!termsAccepted);
							}}
							aria-checked={termsAccepted}
							aria-label={'Accept terms & conditions'}
						>
							{termsAccepted ? (
								<span
									style={{
										position: 'absolute',
										bottom: '-10px',
										left: '2.5px',
										fontSize: '25px',
									}}
								>
									✓
								</span>
							) : (
								''
							)}
						</div>

						{parse(
							`<span class="registration__terms-span" style="margin-left: 20px">${language.acceptTerms}</span>`
						)}
					</div>

					{formError && (
						<div
							className="registration__error-wrapper"
							style={{
								maxWidth: '300px',
								width: '100%',
								border: '1px solid red',
								borderRadius: '2px',
								padding: '10px',
							}}
						>
							<span
								style={{
									color: 'black',
								}}
							>
								{formError}
							</span>
						</div>
					)}
					{createGoogleUser ? (
						<Button
							variant="contained"
							style={{
								maxWidth: '300px',
								width: '100%',
								height: '45px',
								marginTop: '10px',
								fontWeight: 'bold',
								backgroundColor: '#ffc65a',
								color: 'black',
							}}
							onClick={onClickHandlerGoogle}
							disabled={!name || !email || !termsAccepted || disableButton}
						>
							{disableButton ? (
								<div className="loading__spinner"></div>
							) : (
								language.nextButton
							)}
						</Button>
					) : (
						<Button
							variant="contained"
							style={{
								maxWidth: '300px',
								width: '100%',
								height: '45px',
								marginTop: '10px',
								fontWeight: 'bold',
								backgroundColor: '#ffc65a',
								color: 'black',
							}}
							onClick={onClickHandler}
							disabled={
								!name || !email || !password || !termsAccepted || disableButton
							}
						>
							{disableButton ? (
								<div className="loading__spinner"></div>
							) : (
								language.nextButton
							)}
						</Button>
					)}
				</div>
				<div style={{ textAlign: 'center', marginTop: '20px' }}>
					<span
						style={{
							fontSize: '12px',
						}}
					>
						{language.alreadyMember}{' '}
						<button
							style={{
								color: 'blue',
								textDecoration: 'underline',
								backgroundColor: 'transparent',
								border: 'none',
								fontSize: '12px',
								cursor: 'pointer',
							}}
							onClick={() => handleSignIn()}
						>
							{language.signIn}
						</button>
					</span>
				</div>
			</div>
		</div>
	);
}

export default RegisterPremiumStepOne;
