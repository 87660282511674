interface LoaderProps {
  showText?: boolean;
  text?: string;
}

const Loader: React.FC<LoaderProps> = ({
  showText = true,
  text = "Loading...",
}): JSX.Element => {
  return (
    <div className="loading-wrapper">
      <div className="loading__spinner"></div>
      {showText && <p className="loading__text">{text}</p>}
    </div>
  );
};

export default Loader;
