import { useState, createContext } from 'react';
import { api_url } from '../Constants/constants';
export const PlansContext = createContext(null);

export const PlansProvider = (props) => {
	const [stripePricesLoading, setStripePricesLoading] = useState<boolean>(true);
	const [prices, setPrices] = useState<any>([]);
	const [partnerPrice, setPartnerPrice] = useState<any>(null);
	const [collabPrices, setCollabPrices] = useState<any>([]);
	const [stripeCustomerBalance, setStripeCustomerBalance] = useState<number>(0);

	const getPlans = async (locale: string): Promise<any> => {
		try {
			const pricesReq = await fetch(`${api_url}/api/plans/allPrices`);
			const pricesRes = await pricesReq.json();
			if (locale === 'se') {
				setPrices(
					pricesRes.map((price: any) => ({
						nickname: price.nickname,
						id: price.id,
						unit_amount: price.currency_options.sek.unit_amount,
						currency: 'SEK',
						recurring: {
							interval: price?.recurring?.interval,
						},
					}))
				);
				setStripePricesLoading(false);
			} else if (locale === 'dk') {
				setPrices(
					pricesRes.map((price: any) => ({
						nickname: price.nickname,
						id: price.id,
						unit_amount: price.currency_options.dkk.unit_amount,
						currency: 'DKK',
						recurring: {
							interval: price?.recurring?.interval,
						},
					}))
				);
				setStripePricesLoading(false);
			} else if (locale === 'no') {
				setPrices(
					pricesRes.map((price: any) => ({
						nickname: price.nickname,
						id: price.id,
						unit_amount: price.currency_options.nok.unit_amount,
						currency: 'NOK',
						recurring: {
							interval: price?.recurring?.interval,
						},
					}))
				);
				setStripePricesLoading(false);
			} else if (
				locale === 'fi' ||
				locale === 'ee' ||
				locale === 'lv' ||
				locale === 'lt' ||
				locale === 'ax'
			) {
				setPrices(
					pricesRes.map((price: any) => ({
						nickname: price.nickname,
						id: price.id,
						unit_amount: price.currency_options.eur.unit_amount,
						currency: 'EUR',
						recurring: {
							interval: price?.recurring?.interval,
						},
					}))
				);
				setStripePricesLoading(false);
			} else {
				setPrices([]);
				setStripePricesLoading(false);
			}
		} catch (error) {
			console.error(
				`Error ${error.name} when fetching prices in PlansContext. Error Message: ${error.message}`
			);
		}
	};

	const getPartnerPrice = async (priceId: String, locale: string) => {
		try {
			const pricesReq = await fetch(
				`${api_url}/api/plans/partnerPrice/${priceId}`
			);
			const pricesRes = await pricesReq.json();
			if (locale === 'se') {
				setPartnerPrice({
					nickname: pricesRes.nickname,
					id: pricesRes.id,
					unit_amount: pricesRes.currency_options.sek.unit_amount,
					currency: 'SEK',
					recurring: {
						interval: pricesRes?.recurring?.interval,
						trial_period_days: pricesRes?.recurring?.trial_period_days,
					},
				});
			} else if (locale === 'dk') {
				setPartnerPrice({
					nickname: pricesRes.nickname,
					id: pricesRes.id,
					unit_amount: pricesRes.currency_options.dkk.unit_amount,
					currency: 'DKK',
					recurring: {
						interval: pricesRes?.recurring?.interval,
						trial_period_days: pricesRes?.recurring?.trial_period_days,
					},
				});
			} else if (locale === 'no') {
				setPartnerPrice({
					nickname: pricesRes.nickname,
					id: pricesRes.id,
					unit_amount: pricesRes.currency_options.nok.unit_amount,
					currency: 'NOK',
					recurring: {
						interval: pricesRes?.recurring?.interval,
						trial_period_days: pricesRes?.recurring?.trial_period_days,
					},
				});
			} else if (
				locale === 'fi' ||
				locale === 'ee' ||
				locale === 'lv' ||
				locale === 'lt'
			) {
				setPartnerPrice({
					nickname: pricesRes.nickname,
					id: pricesRes.id,
					unit_amount: pricesRes.currency_options.eur.unit_amount,
					currency: 'EUR',
					recurring: {
						interval: pricesRes?.recurring?.interval,
						trial_period_days: pricesRes?.recurring?.trial_period_days,
					},
				});
			} else {
				return;
			}
		} catch (err) {
			console.log(err);
		}
	};

	const getCollabPrices = async (priceIds: Array<string>, locale: string) => {
		if (collabPrices?.length === 0) {
			try {
				for (let price of priceIds) {
					const pricesReq = await fetch(
						`${api_url}/api/plans/partnerPrice/${price}`
					);
					const pricesRes = await pricesReq.json();
					if (locale === 'se') {
						setCollabPrices((prevState) => [
							...prevState,
							{
								nickname: pricesRes.nickname,
								id: pricesRes.id,
								unit_amount: pricesRes.currency_options.sek.unit_amount,
								currency: 'SEK',
								recurring: {
									interval: pricesRes?.recurring?.interval,
								},
							},
						]);
					} else if (locale === 'dk') {
						setCollabPrices((prevState) => [
							...prevState,
							{
								nickname: pricesRes.nickname,
								id: pricesRes.id,
								unit_amount: pricesRes.currency_options.dkk.unit_amount,
								currency: 'DKK',
								recurring: {
									interval: pricesRes?.recurring?.interval,
								},
							},
						]);
					} else if (locale === 'no') {
						setCollabPrices((prevState) => [
							...prevState,
							{
								nickname: pricesRes.nickname,
								id: pricesRes.id,
								unit_amount: pricesRes.currency_options.nok.unit_amount,
								currency: 'NOK',
								recurring: {
									interval: pricesRes?.recurring?.interval,
								},
							},
						]);
					} else if (
						locale === 'fi' ||
						locale === 'ee' ||
						locale === 'lv' ||
						locale === 'lt'
					) {
						setCollabPrices((prevState) => [
							...prevState,
							{
								nickname: pricesRes.nickname,
								id: pricesRes.id,
								unit_amount: pricesRes.currency_options.eur.unit_amount,
								currency: 'EUR',
								recurring: {
									interval: pricesRes?.recurring?.interval,
								},
							},
						]);
					} else {
						return;
					}
				}
			} catch (err) {
				console.log(err);
			}
		} else {
			return;
		}
	};

	// const getGraduatedPrice = async (priceId) => {
	//   let currency: string;
	//   if (locale === "se") {
	//     currency = "sek";
	//   } else if (locale === "no") {
	//     currency = "nok";
	//   } else if (locale === "dk") {
	//     currency = "dkk";
	//   } else {
	//     currency = "eur";
	//   }

	//   var config = {
	//     method: 'get',
	//     url: `${api_url}/api/plans/partnerPrice/${priceId}?currency=${currency}`,
	//     headers: {
	//       'Content-Type': 'application/json',
	//     },
	//   };
	//   const response = await axios(config);

	//   if (response.data) {
	//     // console.log(response.data.currency_options[currency].tiers)
	//     setGraduatedPrice({
	//       nickname: response.data.nickname,
	//       id: response.data.id,
	//       unit_amount: response.data.currency_options[currency].tiers[0].unit_amount,
	//       next_amount: response.data.currency_options[currency].tiers[1].unit_amount,
	//       currency: currency,
	//       recurring: {
	//         interval: response.data?.recurring?.interval
	//       }
	//     });
	//   }
	// };

	return (
		<PlansContext.Provider
			value={{
				stripePricesLoading,
				prices,
				setPrices,
				partnerPrice,
				setPartnerPrice,
				setStripeCustomerBalance,
				stripeCustomerBalance,
				getPartnerPrice,
				getCollabPrices,
				setCollabPrices,
				collabPrices,
				getPlans,
				// getGraduatedPrice,
				// graduatedPrice
			}}
		>
			{props.children}
		</PlansContext.Provider>
	);
};
