const Support: React.FC = (): JSX.Element => {
	return (
		<main className="main">
			<article className="about section">
				<h1 className="about__heading page-heading">Support</h1>
				<p className="about__text">
					Our team in Stockholm are happy to assist you with any technical
					difficulties, bug reports, questions, requests or general feedback
					through one of our customer channels.
				</p>
				<p className="about__text">
					We do our best to respond to queries as quickly as we can. If your
					message concerns an urgent matter, we recommend clarifying this in the
					subject line. We currently offer support in Swedish and English.
				</p>
				<h2 className="about__sub-heading">Email</h2>
				<p className="about__text">
					Send an email to{' '}
					<a href="mailto:support@staccs.com" className="about__text">
						support@staccs.com
					</a>
					. Please make sure you contact us from the same email you have
					registered with Staccs.
				</p>
				<h2 className="about__sub-heading">Socials</h2>
				<p className="about__text">
					Send a message to us on Instagram @worldofstaccs or to our{' '}
					<a
						href="https://www.facebook.com/worldofstaccs/"
						target="_blank"
						className="about__text"
					>
						Facebook page
					</a>
					.
				</p>
			</article>
		</main>
	);
};

export default Support;
