import parse from 'html-react-parser'
import { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../../../Context/language'

const SignupCompleted = ({ signupType }) => {
  const { language } = useContext(LanguageContext)
  const [deviceType, setDeviceType] = useState(null)

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'complete_registration',
    })
    window.dataLayer.push({
      event: `complete_registration_${signupType.toLowerCase()}`,
    })

    navigator.userAgent.toLowerCase().match(/ipad|iphone/i) && setDeviceType('iOS')

    navigator.userAgent.toLowerCase().match(/android/i) && setDeviceType('android')
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <h2
        style={{
          fontWeight: 'bold',
          fontSize: '20px',
          lineHeight: '25px',
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        {language.step4Headline}
      </h2>
      <p
        style={{ fontSize: '14px', textAlign: 'center' }}
        className="registration__welcome-info-text"
      >
        {language.step4row1}
        <br />
        <br />
        {parse(`${language.step4row2}`)}
      </p>
      <div className="registration__app-links">
        {(!deviceType || deviceType === 'iOS') && (
          <a
            href="https://apps.apple.com/us/app/staccs/id1566144790"
            target="_blank"
            rel="noreferrer"
            className="registration__app-link"
          >
            <img
              src="/images/appstore.svg"
              alt={language.footerDownload}
              className="registration__app-link-icon"
            />
          </a>
        )}

        {(!deviceType || deviceType === 'android') && (
          <a
            href="https://play.google.com/store/apps/details?id=com.staccs"
            target="_blank"
            rel="noreferrer"
            className="registration__app-link"
          >
            <img
              src="/images/googleplay.png"
              alt={language.footerDownload}
              className="registration__app-link-icon"
            />
          </a>
        )}
      </div>
      <p
        style={{ fontSize: '13px', textAlign: 'center' }}
        className="registration__welcome-info-footer"
      >
        {language.step4row3} <br />
        Staccs
      </p>
    </div>
  )
}

export default SignupCompleted
