import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { api_url } from '../../Constants/constants';
import { TempAuthContext } from '../../Context/tempAuthContext';
import { RegisterContext } from '../../Context/RegisterContext';
import { DialogContext } from '../../Context/DialogContext';
import jwtDecode from 'jwt-decode';

export const GoogleLogin = ({ nightwish } = { nightwish: false }) => {
	const { setAccessToken, setRefreshToken, setOldToken } =
		useContext(TempAuthContext);
	const { setFormData, formData, setCreateGoogleUser } =
		useContext(RegisterContext);
	const { toggleSignupDialogOpen } = useContext(DialogContext);

	const navigate = useNavigate();

	const randomStringId = (Math.random() * 2340).toFixed(0);

	async function handleCallbackResponse(response) {
		let userObject = jwtDecode(response.credential);

		var config = {
			method: 'post',
			url: `${api_url}/api/auth/googlelogin`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: {
				userObject: userObject,
				tokenId: response.credential,
				name: userObject.name,
			},
		};
		const res = await axios(config);

		if (res.status === 200) {
			setAccessToken(res.data.accessToken);
			setRefreshToken(res.data.refreshToken);
			setOldToken(res.data.oldToken);
			let payload = await jwtDecode(res.data.accessToken);
			setFormData({
				...formData,
				stripeId: payload.stripeId,
				cusomterId: payload.userId,
			});
			toggleSignupDialogOpen(false);
			if (nightwish) {
				navigate('/nightwish');
			}
		} else if (res.status === 202) {
			setFormData({
				...formData,
				email: userObject.email,
				name: userObject.name,
			});
			setCreateGoogleUser(true);
		}
	}

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://accounts.google.com/gsi/client';
		script.async = true;
		script.onload = () => {
			// SDK is loaded, you can now use the google object
			google.accounts.id.initialize({
				client_id:
					'752245826065-ahd31v5sk7tcvnlc0546edpmvn1hmin4.apps.googleusercontent.com',
				callback: handleCallbackResponse,
			});

			google.accounts.id.renderButton(document.getElementById(randomStringId), {
				theme: 'outline',
				size: 'large',
				text: 'signup_with',
			});
		};

		// Append the script to the document to start loading the SDK
		document.body.appendChild(script);
	}, []);

	return <div id={randomStringId}></div>;
};
