import { Routes, Route } from 'react-router-dom';

// ROUTES
import App from './App';
import About from './Routes/About';
import FourOhFour from './Routes/FourOhFour';
import Privacy from './Routes/Privacy';
import Terms from './Routes/Terms';
import Support from './Routes/Support';
import NewHero from './Components/Hero/NewHero';
import Rip from './Components/Hero/Rip';

const Router = () => {
	return (
		<Routes>
			<Route path="/" element={<App />}>
				<Route path="/" element={<Rip />} />

				<Route path="/privacy" element={<Privacy />} />
				<Route path="/terms" element={<Terms />} />
				{/* <Route path="/about" element={<About />} /> */}
				<Route path="/support" element={<Support />} />

				{/* 404 - Page Not Found */}
				<Route path="*" element={<FourOhFour />} />
			</Route>
		</Routes>
	);
};

export default Router;
