import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { RegisterContext } from './RegisterContext';
import { api_url } from '../Constants/constants';

export const TempAuthContext = createContext();

// const API_URL = 'http://localhost:5050/api'
const API_URL = 'https://devapi.svc.staccs.se/api';

export const TempAuthProvider = (props) => {
	const { setFormData } = useContext(RegisterContext);
	const [accessToken, setAccessToken] = useState(null);
	const [oldToken, setOldToken] = useState(null);
	const [refreshToken, setRefreshToken] = useState(null);
	const [userProfile, setUserProfile] = useState({});
	const [user, setUser] = useState();
	const [role, setRole] = useState('');
	const [stripeCustomerId, setStripeCustomerId] = useState('');
	const [appRedirectToken, setAppRedirectToken] = useState(null);

	useEffect(() => {
		if (accessToken) {
			localStorage.setItem('access_token', accessToken);
			const tokenPayload = jwtDecode(accessToken);
			setRole(tokenPayload.role);
			setStripeCustomerId(tokenPayload.stripeId);
			getProfile();
		}
	}, [accessToken]);

	useEffect(() => {
		if (oldToken) {
			localStorage.setItem('old_token', oldToken);
		}
	}, [oldToken]);

	useEffect(() => {
		if (refreshToken) {
			localStorage.setItem('refresh_token', refreshToken);
		}
	}, [refreshToken]);

	useEffect(() => {
		const accessTokenFromStorage = localStorage.getItem('access_token');
		const refreshTokenFromStorage = localStorage.getItem('refresh_token');
		if (accessTokenFromStorage) {
			setAccessToken(accessTokenFromStorage);
		}
		if (refreshTokenFromStorage) {
			setRefreshToken(refreshTokenFromStorage);
		}

		checkAccessToken();
	}, []);

	const signIn = async (username, password) => {
		try {
			const response = await axios.post(`${API_URL}/user/sign-in`, {
				username,
				password,
			});
			if (response.status === 200) {
				const { data } = response;
				setAccessToken(data.accessToken);
				setOldToken(data.oldToken);
				setRefreshToken(data.refreshToken);

				if (data.refreshToken) {
					localStorage.setItem('refresh_token', data.refreshToken);
				}

				if (data.accessToken) {
					localStorage.setItem('access_token', data.accessToken);
					const tokenPayload = jwtDecode(data.accessToken);
					setRole(tokenPayload.role);
					getProfile();
				} else {
					localStorage.removeItem('access_token');
				}
			}
			return response.data;
		} catch (err) {
			return err.response.status;
		}
	};

	const getProfile = async () => {
		await checkAccessToken();
		try {
			const authToken = localStorage.getItem('access_token');
			const response = await axios.get(`${API_URL}/profile`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			});
			setUserProfile(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	const getAccount = async () => {
		try {
			const newToken = await checkAccessToken();
			const response = await fetch(`${api_url}/api/account`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + newToken,
				},
			});
			const data = await response.json();
			if (response.status === 200) {
				setUser(data);
			}
		} catch (error) {
			console.error('error', error);
		}
	};

	// On render, chack accessToken validity
	// Get new token if old ass token
	const getNewAccessToken = async (refreshToken) => {
		try {
			const response = await axios.post(`${API_URL}/user/token`, {
				refreshToken,
			});
			const { data } = response;
			setAccessToken(data.accessToken);
			return data.accessToken;
		} catch (err) {
			return err.response.status;
		}
	};

	const getAccessFromUrlToken = async (urlToken) => {
		const response = await fetch(`${API_URL}/account/token/access`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + urlToken,
			},
		});
		const data = await response.json();
		if (response.status === 200) {
			getAccount(data.accessToken);
			setAccessToken(data.accessToken);
			setRefreshToken(data.refreshToken);
			localStorage.setItem('refresh_token', refreshToken);
		}
	};

	const createAccountToken = async () => {
		try {
			const response = await fetch(`${API_URL}/account/token`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + accessToken,
				},
			});
			const data = await response.json();
			if (response.status === 200) {
				setAppRedirectToken(data.token);
				return data.token;
			}
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const logout = () => {
		setAccessToken(null);
		setRefreshToken(null);
		setUserProfile(null);
		setRole(null);
		setFormData({
			name: '',
			email: '',
			password: '',
			country: '',
		});
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		localStorage.removeItem('old_token');
		window.location.reload();
	};

	const checkAccessToken = async () => {
		const refreshToken = localStorage.getItem('refresh_token');
		if (accessToken) {
			const tokenPayload = jwtDecode(accessToken);

			const newTimeNow = Math.floor(Date.now() / 1000);

			if (newTimeNow > tokenPayload.exp) {
				return await getNewAccessToken(refreshToken);
			} else {
				return accessToken;
			}
		}
	};

	// const addTokenToAxiosRequests = async () => {
	//   if (accessToken) {
	//     await checkAccessToken();
	//     axios.interceptors.request.use(
	//       function (config) {
	//         config.headers["Authorization"] = `Bearer ${accessToken}`;
	//         return config;
	//       },
	//       function (err) {
	//         return Promise.reject(err);
	//       }
	//     );
	//   }
	// };

	// addTokenToAxiosRequests();

	return (
		<TempAuthContext.Provider
			value={{
				accessToken,
				userProfile,
				role,
				signIn,
				logout,
				getAccount,
				user,
				stripeCustomerId,
				setStripeCustomerId,
				getAccessFromUrlToken,
				createAccountToken,
				appRedirectToken,
				setAccessToken,
				setRefreshToken,
				setOldToken,
				getNewAccessToken,
			}}
		>
			{props.children}
		</TempAuthContext.Provider>
	);
};
