import { useState, createContext } from 'react'
export const RegisterContext = createContext()

export const RegisterProvider = props => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    country: '',
  })
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState(null)
  const [createGoogleUser, setCreateGoogleUser] = useState(false)

  return (
    <RegisterContext.Provider
      value={{
        formData,
        setFormData,
        termsAccepted,
        setTermsAccepted,
        paymentIntentClientSecret,
        setPaymentIntentClientSecret,
        createGoogleUser,
        setCreateGoogleUser
      }}
    >
      {props.children}
    </RegisterContext.Provider>
  )
}
