import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContext } from '../../../../Context/DialogContext';
import RegisterSwitch from './RegisterSwitch';

const SimpleDialog: React.FC = (): JSX.Element => {
	const { signupDialogOpen: open, setSignupDialogOpen } =
		useContext(DialogContext);

	const handleClose = (): void => {
		setSignupDialogOpen(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			fullWidth
			maxWidth="xl"
			style={{ maxWidth: '500px', margin: 'auto' }}
		>
			<div className="signup-dialog">
				<RegisterSwitch />
			</div>
		</Dialog>
	);
};

export default SimpleDialog;
