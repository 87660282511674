import stage from '../../../src/Assets/Images/herostage.jpg';
import { useContext } from 'react';
import { TempAuthContext } from '../../Context/tempAuthContext';

import { SoundwaveAnimation } from '../SoundwaveAnimation/SoundwaveAnimation';
import CtaButton from '../Buttons/CTA/CtaButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LanguageContext } from '../../Context/language';

const NewHero = () => {
	const { appRedirectToken } = useContext<any>(TempAuthContext);
	const { language } = useContext(LanguageContext);

	return (
		<>
			<div className="hero__container">
				<div
					className="hero__wrapper"
					// style={{ backgroundImage: `url(${stage})` }}
				>
					<div className="hero-copy__wrapper">
						<div className="headline-parapgraph-cta__wrapper">
							{/* <p
								style={{
									textAlign: 'left',
									color: 'rgb(171, 143, 234)',
									marginBottom: '8px',
									fontSize: '20px',
								}}
							>
								2021-2024
							</p> */}
							<h1 className="hero-header-copy">
								{/* {language?.newHero?.newHeroHeading1} */}
								Thank You for Being Part of Our Journey
							</h1>
							<h2
								className="hero-header-copy"
								style={{ fontSize: '24px', marginTop: '16px' }}
							>
								{/* {language?.newHero?.newHeroHeading2} */}
								To Our Valued Subscribers,
							</h2>
							<p className="hero-copy">
								We are incredibly grateful for your support and for being part
								of our music streaming family. Over the years, we’ve had the
								privilege of bringing you some of the best live concerts, music
								documentaries, and unforgettable performances from around the
								world.
							</p>
							<p className="hero-copy">
								As of 2024-09-30, our service is no longer live, and we have
								made the difficult decision to close this chapter. While we are
								sad to say goodbye, we are deeply thankful for the community
								we’ve built and for the moments we’ve shared through the power
								of music.
							</p>
							<p className="hero-copy">
								For those of you still wanting to enjoy our music channels,
								we’re excited to share that you can continue streaming them for
								free through Tevefy at{' '}
								<a
									href="https://tevefy.com"
									style={{ fontSize: '20px', color: '#AB8FEA' }}
								>
									www.tevefy.com.
								</a>{' '}
								We hope you’ll join us there and keep the music going!
							</p>
							<p className="hero-copy">
								If you have any questions or require assistance, please feel
								free to reach out to{' '}
								<strong style={{ fontSize: '20px' }}>
									{' '}
									support@staccs.com.
								</strong>
							</p>
							<p className="hero-copy">
								Once again, thank you for the music, the memories, and your
								unwavering support.
							</p>
							<p className="hero-copy">
								Rock on, <br />
								<strong style={{ fontSize: '20px', color: '#FAD86F' }}>
									World of Staccs
								</strong>
							</p>
							<div className="button__wrapper">
								{/* <button>immerse yourself</button> */}
								<CtaButton
									fontSize={'20px'}
									bgHover={true}
									link={`https://tevefy.com`}
									text={'Go to Tevefy'}
									arrow={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NewHero;
