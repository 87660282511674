import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { RegisterContext } from '../../../../../Context/RegisterContext';
import { TempAuthContext } from '../../../../../Context/tempAuthContext';
import { LanguageContext } from '../../../../../Context/language';
import axios from 'axios';
import { api_url } from '../../../../../Constants/constants';
import { PlansContext } from '../../../../../Context/PlansContext';
import { GoogleLogin } from '../../../../../Utils/helpers/GoogleOauth2';
import { DialogContext } from '../../../../../Context/DialogContext';
import { VideoControllerContext } from '../../../../../Context/videoContext';

function RegisterFree({ view, setView, setSignupType }) {
	const { subtitle, partnerTitle, partnerName, setSignupDialogOpen } =
		useContext(DialogContext);
	const { language } = useContext(LanguageContext);
	const { setClickedPartnerPlay } = useContext(VideoControllerContext);
	const {
		formData,
		setFormData,
		termsAccepted,
		setTermsAccepted,
		createGoogleUser,
	} = useContext(RegisterContext);
	const { signIn, setAccessToken, setRefreshToken, setOldToken, accessToken } =
		useContext(TempAuthContext);
	const { prices } = useContext(PlansContext);
	const { name, email, password } = formData;
	const [memberPrice, setMemberPrice] = useState();
	const [formError, setFormError] = useState(null);
	const [disableButton, setDisableButton] = useState(false);
	const locale = window.localStorage.getItem('locale');

	useEffect(() => {
		setSignupType('member');

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'member_signup_modal',
		});

		let foundPrice = prices.find((price) => price.nickname === 'Member');
		setMemberPrice(foundPrice);
	}, []);

	const onClickHandlerGoogle = async () => {
		setDisableButton(true);
		var config = {
			method: 'post',
			url: `${api_url}/api/auth/google-create`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: {
				name: formData.name,
				email: formData.email,
				country: locale,
				campaign: partnerName,
			},
		};

		const res = await axios(config);

		if (res.status === 201) {
			setAccessToken(res.data.tokens.accessToken);
			setRefreshToken(res.data.tokens.refreshToken);
			setOldToken(res.data.tokens.oldToken);
		} else {
			setDisableButton(false);
		}
	};

	const onClickHandler = async () => {
		setDisableButton(true);
		setFormError(null);
		const response = await fetch(`${api_url}/api/signup/createaccount`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				...formData,
				paymentType: 'stripe',
				country: locale,
				campaign: partnerName,
			}),
		});

		const data = await response.json();

		if (response.status === 400) {
			setDisableButton(false);
			if (data.errors) {
				data.errors[0].param === 'email' && setFormError('Invalid email');
				data.errors[0].param === 'password' &&
					setFormError('Password has to be more than 8 characters');
				return;
			}
			switch (data.msg) {
				case 'User already exists':
					setFormError(data.msg);
					return;
				case 'Username is too short':
					setFormError(data.msg);
					return;
				case 'Password is too short':
					setFormError(data.msg);
					setFormData({ ...formData, password: '' });
					return;
				default:
					break;
			}
			return;
		} else if (response.status === 200) {
			if (!createGoogleUser) {
				signIn(formData.email, formData.password);
			}
			setSignupDialogOpen(false);
			setClickedPartnerPlay(true);
		}
	};

	const handleSignIn = () => {
		setView('SIGNIN');
	};

	const onInputChange = (event) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					fontSize: '20px',
					fontWeight: '500',
					textAlign: 'center',
					marginBottom: '20px',
				}}
			>
				<h2
					style={{
						fontSize: 20,
					}}
				>
					Skapa ett konto kostnadsfritt
				</h2>
				<p
					style={{
						fontSize: 14,
					}}
				>
					för att förhandstitta på {partnerTitle} {subtitle}
				</p>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
				{!createGoogleUser && (
					<>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								maxWidth: '300px',
								width: '100%',
								margin: 'auto',
							}}
						>
							<GoogleLogin nightwish={false} />
						</div>
						<span className="registration__form-divider">or</span>
					</>
				)}
			</div>
			<div>
				<div
					style={{
						gap: '15px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<TextField
						className="outlined-basic"
						label="Email"
						type="email"
						name="email"
						value={email}
						InputLabelProps={{ style: { fontSize: '12px' } }}
						InputProps={{ style: { fontSize: '12px' } }}
						variant="outlined"
						style={{
							maxWidth: '300px',
							width: '100%',
						}}
						onChange={(e) => onInputChange(e)}
					/>

					{!createGoogleUser && (
						<TextField
							className="outlined-basic"
							label="Password"
							name="password"
							value={password}
							variant="outlined"
							InputLabelProps={{ style: { fontSize: '12px' } }}
							InputProps={{ style: { fontSize: '12px' } }}
							style={{ maxWidth: '300px', width: '100%' }}
							type="password"
							onChange={(e) => onInputChange(e)}
						/>
					)}

					<div className={'registration__terms-wrapper'}>
						<div
							role={'checkbox'}
							tabIndex={0}
							className="registration__terms-checkbox"
							onClick={() => {
								setTermsAccepted(!termsAccepted);
							}}
							aria-checked={termsAccepted}
							aria-label={'Accept terms & conditions'}
						>
							{termsAccepted ? (
								<span
									style={{
										position: 'absolute',
										bottom: '-10px',
										left: '2.5px',
										fontSize: '25px',
									}}
								>
									✓
								</span>
							) : (
								''
							)}
						</div>

						{parse(
							`<span class="registration__terms-span" style="margin-left: 20px">${language.acceptTerms}</span>`
						)}
					</div>

					{formError && (
						<div
							className="registration__error-wrapper"
							style={{
								maxWidth: '300px',
								width: '100%',
								border: '1px solid red',
								borderRadius: '2px',
								padding: '10px',
							}}
						>
							<span
								style={{
									color: 'black',
								}}
							>
								{formError}
							</span>
						</div>
					)}

					{createGoogleUser ? (
						<Button
							variant="contained"
							style={{
								maxWidth: '300px',
								width: '100%',
								height: '45px',
								marginTop: '10px',
								fontWeight: 'bold',
								backgroundColor: '#ffc65a',
								color: 'black',
							}}
							onClick={onClickHandlerGoogle}
							disabled={!email || !termsAccepted || disableButton}
						>
							{disableButton ? (
								<div className="loading__spinner"></div>
							) : (
								language.nextButton
							)}
						</Button>
					) : (
						<Button
							variant="contained"
							style={{
								maxWidth: '300px',
								width: '100%',
								height: '45px',
								fontSize: '12px',
								marginTop: '10px',
								fontWeight: '600',
								backgroundColor: '#ffc65a',
								color: 'black',
							}}
							onClick={onClickHandler}
							disabled={!email || !password || !termsAccepted || disableButton}
						>
							{disableButton ? (
								<div className="loading__spinner"></div>
							) : (
								language.signUp
							)}
						</Button>
					)}
				</div>
				<div style={{ textAlign: 'center', marginTop: '20px' }}>
					<span
						style={{
							fontSize: '12px',
						}}
					>
						{language.alreadyMember}{' '}
						<button
							style={{
								color: 'blue',
								textDecoration: 'underline',
								backgroundColor: 'transparent',
								border: 'none',
								fontSize: '12px',
								cursor: 'pointer',
							}}
							onClick={() => handleSignIn()}
						>
							{language.signIn}
						</button>
					</span>
				</div>
			</div>
		</div>
	);
}

export default RegisterFree;
