import parse from 'html-react-parser'
import { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../../../Context/language'

const MemberSignupCompleted = ({ signupType }) => {
  const { language } = useContext(LanguageContext)
  const [deviceType, setDeviceType] = useState(null)
  const locale = localStorage.getItem("locale")

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'complete_registration',
    })
    window.dataLayer.push({
      event: `complete_registration_${signupType.toLowerCase()}`,
    })
    navigator.userAgent.toLowerCase().match(/ipad|iphone/i) && setDeviceType('iOS')

    navigator.userAgent.toLowerCase().match(/android/i) && setDeviceType('android')
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <h2
        style={{
          fontWeight: 'bold',
          fontSize: '20px',
          lineHeight: '25px',
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        {language.step4Headline}
      </h2>

      {locale === "se" ? (
        <p
          style={{ fontSize: '14px', textAlign: 'center' }}
          className="registration__welcome-info-text"
        >
          {language.step4row1}
          <br />
          <br />
          {parse(`Börja använda Staccs genom att logga in på <a target="_blank" href="https://staccs.com/discovery">staccs.com/discovery</a> för att se vårat medlemsinnehåll.`)}
        </p>
      ) : (
        <p
          style={{ fontSize: '14px', textAlign: 'center' }}
          className="registration__welcome-info-text"
        >
          {language.step4row1}
          <br />
          <br />
          {parse(`Start using Staccs by logging in to your account on <a target="_blank" href="https://staccs.com/discovery">staccs.com/discovery</a> to see our member content.`)}
        </p>
      )}
      <p
        style={{ fontSize: '13px', textAlign: 'center', marginTop: "30px" }}
        className="registration__welcome-info-footer"
      >
        {language.step4row3} <br />
        Staccs
      </p>
    </div>
  )
}

export default MemberSignupCompleted