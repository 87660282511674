import { Button } from "@mui/material";
import { useContext, useState, useEffect } from "react";
// import {
//   GoogleLoginButton,
//   AppleLoginButton,
// } from "react-social-login-buttons";
import { LanguageContext } from "../../../../../Context/language";
import axios from "axios";
import { api_url } from "../../../../../Constants/constants";
import { TempAuthContext } from "../../../../../Context/tempAuthContext";
import jwtDecode from "jwt-decode";
import { PlansContext } from "../../../../../Context/PlansContext";

function UpgradeFree({ setView }) {
  const { language } = useContext(LanguageContext);
  const { accessToken } = useContext(TempAuthContext);
  const { prices } = useContext(PlansContext);
  const [formError, setFormError] = useState(null);
  const [memberPrice, setMemberPrice] = useState()
  const locale = window.localStorage.getItem("locale");
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    let foundPrice = prices.find(
      (price) => price.nickname === "Member",
    )
    setMemberPrice(foundPrice);
  }, [])

  const onClickHandler = async () => {
    setDisableButton(true);
    setFormError(null);
    let decodedToken = jwtDecode(accessToken);

    // hardcoded ID's hell yes!
    let priceId = "price_1LziufFJBmxpVNzMINThILNh";
    let foundPrice = prices.find(
      (price) => price.id === priceId,
    )
    let currency = foundPrice.currency;
    try {
      var config = {
        method: "post",
        url: `${api_url}/api/signup/createfreemium`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          stripeId: decodedToken.stripeId,
          userId: decodedToken.userId,
          priceId,
          currency
        }),
      };
      const freemiumResponse = await axios(config);
      // CREATE FREEMIUM STATUS OK !
      if (freemiumResponse.status === 200) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'complete_registration_member',
        })
        setView("MEMBERSIGNUPCOMPLETED");
      } else {
        setDisableButton(false);
        setFormError("Something went wrong...");
      }
    } catch (error) {
      setFormError("Something went wrong...");
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        <h2
          style={{
            fontSize: 18,
          }}
        >
          {language?.signUpDiscovery?.startFree?.heading}
        </h2>
        <p
          style={{
            fontSize: 14,
          }}
        >
          {language?.signUpDiscovery?.startFree?.subHeading}
        </p>
        {locale === "se" ? (
          <p
            style={{
              fontSize: 10,
            }}
          >
            {`(Värde ${memberPrice?.unit_amount * 12 / 100} ${memberPrice?.currency})`}
            {` sedan ${memberPrice?.unit_amount / 100
              } ${memberPrice?.currency.toLocaleUpperCase()}/månaden`}
          </p>
        ) : (
          <p
            style={{
              fontSize: 10,
            }}
          >
            {`(Value ${memberPrice?.unit_amount * 12 / 100} ${memberPrice?.currency})`}
            {` then ${memberPrice?.unit_amount / 100
              } ${memberPrice?.currency.toLocaleUpperCase()}/month`}
          </p>
        )}
      </div>

      <div>
        <div
          style={{
            gap: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {formError && (
            <div
              className="registration__error-wrapper"
              style={{
                maxWidth: "300px",
                width: "100%",
                border: "1px solid red",
                borderRadius: "2px",
                padding: "10px",
              }}
            >
              <span
                style={{
                  color: "black",
                }}
              >
                {formError}
              </span>
            </div>
          )}

          <Button
            variant="contained"
            style={{
              maxWidth: "300px",
              width: "100%",
              height: "45px",
              marginTop: "10px",
              fontWeight: "bold",
              backgroundColor: "#ffc65a",
              color: "black",
              fontSize: "12px"
            }}
            onClick={onClickHandler}
            disabled={disableButton}
          >
            {disableButton ? (
              <div className="loading__spinner"></div>
            ) : (
              language.becomeMember
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UpgradeFree;
