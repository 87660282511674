import { useState, createContext } from 'react';

export const VideoControllerContext = createContext();

export const VideoControllerProvider = (props) => {
	const [videoVolume, setVideoVolume] = useState(50);
	const [videoMuted, setVideoMuted] = useState(false);
	const [titlePlaying, setTitlePlaying] = useState('');
	const [clickedPartnerPlay, setClickedPartnerPlay] = useState(false);

	return (
		<VideoControllerContext.Provider
			value={{
				clickedPartnerPlay,
				setClickedPartnerPlay,
				titlePlaying,
				setTitlePlaying,
				videoVolume,
				setVideoVolume,
				videoMuted,
				setVideoMuted,
			}}
		>
			{props.children}
		</VideoControllerContext.Provider>
	);
};
