import LostGif from "../Assets/Images/lost.gif";

const FourOhFour: React.FC = (): JSX.Element => {
  return (
    <div className="four-oh-four section bg-dark">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={LostGif}
          alt=""
          style={{ width: "150px", marginBottom: "10px" }}
        />
        <span className="four-oh-four__text">
          Oops... <br /> Looks like you're lost.
        </span>
      </div>
    </div>
  );
};

export default FourOhFour;
