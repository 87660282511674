import { useContext, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import GoogleButton from '../register-free/google-button/GoogleButton';
import AppleButton from '../register-free/apple-button/AppleButton';
import { RegisterContext } from '../../../../../Context/RegisterContext';
import { LanguageContext } from '../../../../../Context/language';
import { PlansContext } from '../../../../../Context/PlansContext';
import { TempAuthContext } from '../../../../../Context/tempAuthContext';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { api_url } from '../../../../../Constants/constants';

function UpgradePremiumStepOne({ view, setView }) {
	const { language } = useContext(LanguageContext);
	const { formData, setFormData, setPaymentIntentClientSecret } =
		useContext(RegisterContext);
	const { prices, setSelectedPrice, selectedPrice } = useContext(PlansContext);
	const { accessToken, userProfile } = useContext(TempAuthContext);
	const [showError, toggleShowError] = useState(false);
	const locale = window.localStorage.getItem('locale');
	const [disableButton, setDisableButton] = useState(false);

	useEffect(() => {
		let decodedToken;
		if (accessToken) {
			decodedToken = jwtDecode(accessToken);
			setFormData({
				...formData,
				email: userProfile.email,
				name: userProfile.name,
				stripeId: decodedToken.stripeId,
				customerId: decodedToken.userId,
			});
		}
	}, []);

	const onClickHandler = async (price) => {
		setDisableButton(true);
		setFormData({
			...formData,
			priceId: price?.id,
			amount: price?.unit_amount,
			currency: price?.currency,
			nickname: price?.nickname,
		});

		if (showError) {
			toggleShowError(false);
		}

		if (!price.id) {
			toggleShowError(true);
			return;
		}

		if (price?.nickname === '72h') {
			await createStripePaymentIntent(price);
		} else {
			let priceId = price.id;
			let customerId = formData.customerId;
			let stripeId = formData.stripeId;
			let currency = price.currency;
			try {
				var config = {
					method: 'post',
					url: `${api_url}/api/signup/createsubscription`,
					headers: {
						'Content-Type': 'application/json',
					},
					data: JSON.stringify({
						customerId,
						stripeId,
						priceId,
						currency,
					}),
				};
				const response = await axios(config);

				if (response.status === 200) {
					setPaymentIntentClientSecret(response.data.clientSecret);
				}
			} catch (error) {
				console.log(error);
			}
		}
		setView('UPGRADEPREMIUMSTEPTWO');
	};

	const createStripePaymentIntent = async (price) => {
		let priceId = price.id;
		let customerId = formData.customerId;
		let stripeId = formData.stripeId;
		let currency = price.currency;
		let amount = price.unit_amount;

		var config = {
			method: 'post',
			url: `${api_url}/api/signup/payment-intent`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: JSON.stringify({
				customerId,
				stripeId,
				priceId,
				amount,
				currency,
			}),
		};
		const intentResponse = await axios(config);
		setPaymentIntentClientSecret(intentResponse.data.client_secret);
		setView('UPGRADEPREMIUMSTEPTWO');
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					fontSize: '20px',
					fontWeight: 'bold',
					textAlign: 'center',
				}}
			>
				<h2
					style={{
						fontSize: 18,
					}}
				>
					{language.signUpDiscovery.upgradePremium.heading}
				</h2>
				<p
					style={{
						fontSize: 14,
					}}
				>
					{language.signUpDiscovery.upgradePremium.subHeading}
				</p>
			</div>
			<div
				style={{
					fontWeight: 'bold',
					margin: '0px 0px 20px 0px',
					padding: '0 10px',
				}}
			>
				<ul
					style={{
						margin: 'auto',
						maxWidth: 150,
					}}
				>
					{language.USPs.yearlySub
						.filter((usp) => usp.active)
						.map((usp, index) => (
							<li key={index} style={{ margin: '5px 0px', fontSize: '13px' }}>
								<i className="fa fa-check" style={{ marginRight: '5px' }}></i>
								{usp.text}
							</li>
						))}
				</ul>
			</div>

			<div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '15px',
					}}
				>
					{prices
						?.filter((price) => price.nickname !== 'Member')
						.map((price) => (
							<Button
								key={price.id}
								style={{ backgroundColor: '#ffc65a' }}
								variant="contained"
								onClick={() => {
									onClickHandler(price);
								}}
								disabled={disableButton}
							>
								<div
									style={{
										width: '100%',
										height: '40px',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center',
										fontWeight: 'bold',
									}}
								>
									{locale === 'se' ? (
										<span style={{ fontSize: '13px', color: 'black' }}>
											{price?.nickname === 'Monthly'
												? 'Månad'
												: price?.nickname === 'Yearly'
												? 'År'
												: price?.nickname === 'Member'
												? 'Medlem'
												: price?.nickname}
										</span>
									) : (
										<span style={{ fontSize: '13px', color: 'black' }}>
											{price?.nickname}
										</span>
									)}
									<span style={{ fontSize: '13px', color: 'black' }}>
										{(price?.unit_amount / 100).toLocaleString() +
											' ' +
											price?.currency?.toLocaleUpperCase()}
									</span>
								</div>
							</Button>
						))}
				</div>
			</div>
		</div>
	);
}

export default UpgradePremiumStepOne;
