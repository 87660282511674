import { useContext, useState } from 'react';
import { DialogContext } from '../../../../Context/DialogContext';
import Fallback from './Fallback';
import RegisterFree from './register-free/RegisterFree';
import SignupCompleted from './SignupCompleted';
import MemberSignupCompleted from './MemberSignupCompleted';
import RegisterPremiumStepOne from './register-premium/RegisterPremiumStepOne';
import RegisterPremiumStepTwo from './register-premium/RegisterPremiumStepTwo';
import RegisterPremiumStepThree from './register-premium/RegisterPremiumStepThree';
import SignIn from '../../../SignInModal/SignIn';
import PartnerSignUp from './partner-sign-up/PartnerSignUp';
import UpgradeFree from './upgrade-free/UpgradeFree';
import UpgradePremiumStepOne from './upgrade-premium/UpgradePremiumStepOne';
import UpgradePremiumStepTwo from './upgrade-premium/UpgradePremiumStepTwo';
import GiftSong from '../big-card/gift/GiftModal';
import { TempAuthContext } from '../../../../Context/tempAuthContext';

function RegisterSwitch() {
	const { view, setView } = useContext(DialogContext);
	const { getNewAccessToken } = useContext(TempAuthContext);
	const [signupType, setSignupType] = useState('');
	const refreshToken = localStorage.getItem('refresh_token');
	let CurrentView = Fallback;

	switch (view) {
		// FREE
		case 'REGISTERFREE':
			CurrentView = RegisterFree;
			break;
		case 'UPGRADEFREE':
			CurrentView = UpgradeFree;
			break;
		// PREMIUM
		case 'REGISTERPREMIUMSTEPONE':
			CurrentView = RegisterPremiumStepOne;
			break;
		case 'REGISTERPREMIUMSTEPTWO':
			CurrentView = RegisterPremiumStepTwo;
			break;
		case 'REGISTERPREMIUMSTEPTHREE':
			CurrentView = RegisterPremiumStepThree;
			break;
		case 'UPGRADEPREMIUMSTEPONE':
			CurrentView = UpgradePremiumStepOne;
			break;
		case 'UPGRADEPREMIUMSTEPTWO':
			CurrentView = UpgradePremiumStepTwo;
			break;
		case 'SIGNUPCOMPLETED':
			CurrentView = SignupCompleted;
			getNewAccessToken(refreshToken);
			break;
		case 'MEMBERSIGNUPCOMPLETED':
			CurrentView = MemberSignupCompleted;
			getNewAccessToken(refreshToken);
			break;
		case 'SIGNIN':
			CurrentView = SignIn;
			break;
		case 'GIFTSONG':
			CurrentView = GiftSong;
			break;
		case 'PARTNERCAMPAIGN':
			CurrentView = PartnerSignUp;
			break;

		default:
			CurrentView = SignIn;
			break;
	}

	return (
		<div>
			<CurrentView
				view={view}
				setView={setView}
				signupType={signupType}
				setSignupType={setSignupType}
			/>
		</div>
	);
}

export default RegisterSwitch;
