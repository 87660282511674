import React, { useEffect, useState } from "react";

export const FilterContext = React.createContext();

export const FilterProvider = (props) => {
  const [genre, setGenre] = useState("");

  return (
    <FilterContext.Provider
      value={{
        genre,
        setGenre,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};
