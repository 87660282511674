import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useState } from 'react';

interface CtaButtonProps {
	link: string;
	text: string;
	textColor?: string;
	bgColor?: string;
	borderColor?: string;
	boxShadow?: string;
	params?: string;
	internLink?: boolean;
	arrow?: boolean;
	bgHover?: boolean;
	fontSize?: string;
}

const CtaButton: React.FC<CtaButtonProps> = ({
	arrow,
	link,
	text,
	textColor = 'black',
	bgColor = 'white',
	bgHover,
	borderColor = 'transparent',
	boxShadow,
	params,
	internLink,
	fontSize,
}): JSX.Element => {
	const navigate = useNavigate();

	if (internLink) {
		const submit = (
			event: React.MouseEvent,
			link: string,
			params: string
		): void => {
			event.preventDefault();

			navigate(link, {
				state: params,
			});
		};
		return (
			<a
				onKeyDown={(e) => {
					e.key === 'Enter' &&
						navigate(link, {
							state: params,
						});
				}}
				tabIndex={0}
				className={bgHover ? 'cta-hover-effect' : 'cta-button'}
				style={{
					background: bgColor,
					color: textColor,
					borderColor: borderColor,
					boxShadow: boxShadow,
				}}
				onClick={(e) => submit(e, link, params)}
			>
				{text}
			</a>
		);
	} else {
		// external link
		return (
			<a
				tabIndex={0}
				href={link}
				className={bgHover ? 'cta-hover-effect' : 'cta-button'}
				style={{
					display: 'flex',
					gap: '8px',
					borderRadius: '8px',
					fontSize: fontSize,
					background: bgColor,
					color: textColor,
					borderColor: borderColor,
					boxShadow: boxShadow,
					alignItems: 'center',
				}}
				onClick={(e) => e}
			>
				{text}
				{arrow && (
					<ArrowForwardIcon
						fontSize="large"
						htmlColor="rgba(197, 197, 197, 0.5);"
					/>
				)}
			</a>
		);
	}
};

export default CtaButton;
