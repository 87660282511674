export const api_url =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:5001'
		: 'https://staccs.com';
// process.env.NODE_ENV === 'development'
// 	? 'http://localhost:5001'
// 	: 'https://landing.staccs.com';
// process.env.NODE_ENV === 'development'
// 	? 'https://staccs.com'
// 	: 'https://staccs.com';
