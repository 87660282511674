import React, { useContext, useEffect, useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { RegisterContext } from '../../Context/RegisterContext'
import { LanguageContext } from '../../Context/language'
// import { UpgradeContext } from '../../Context/UpgradeContext'
import { TempAuthContext } from '../../Context/tempAuthContext'
import stripeImg from '../../Assets/Images/blurple.svg'

interface CheckoutFormProps {
  setStep?: React.Dispatch<React.SetStateAction<number>>
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ setStep }) => {
  const { formData, paymentIntentClientSecret } = useContext(RegisterContext)
  // const { setSetupConfirmed, priceId, recurring } = useContext(UpgradeContext)
  const { accessToken, userProfile } = useContext(TempAuthContext)
  const { language } = useContext(LanguageContext)
  const [inactiveButton, setInactiveButton] = useState<boolean>(false)
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = paymentIntentClientSecret

    if (!formData.recurring && !clientSecret) {
      return
    }
  }, [stripe])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setInactiveButton(true)
    setLoadingPayment(true)
    setError('')

    if (!stripe || !elements) {
      return
    }

    setInactiveButton(true)

    confirmOneTimePayment()
  }

  const confirmOneTimePayment = async () => {
    const payload = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: '' },
      redirect: 'if_required',
    })

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`)
      setInactiveButton(false)
      setLoadingPayment(false)
    } else {
      setError('Success')
      setLoadingPayment(false)
      setInactiveButton(false)
      setStep(4)
    }
  }

  const handleChange = async (event: any) => {
    setError(event.error ? event.error.message : '')
  }

  return (
    <fieldset>
      <form className="checkout-form" onSubmit={handleSubmit}>
        <div className="payment-element-wrapper">
          <PaymentElement
            onChange={handleChange}
            options={{
              wallets: { applePay: 'never', googlePay: 'never' },
            }}
          />
        </div>
        <img
          style={{
            maxWidth: 120,
          }}
          src={stripeImg}
          alt="powered by stripe"
        />
        {formData.freeTrialDays ? (
          <p style={{ fontSize: '16px' }}>
            {language.step3row3}
            {formData.freeTrialDays}
            {language.step3row3_2}
            {formData.interval === 'year' ? language.yearly : language.monthly}
            .
            <br />
            <br />
            {language.step3row4} {formData.email}
            <br />
            <br />
          </p>
        ) : (
          <p className="end-text">
            {language.step3row4} {formData.email || userProfile.email}
          </p>
        )}
        <button
          className="registration__submit-btn"
          type="submit"
          disabled={!stripe || inactiveButton}
        >
          {loadingPayment ? (
            <div className="loading__spinner"></div>
          ) : (
            language.completeRegistration
          )}
        </button>
        {error && <p className="upgrade__error-message">{error}</p>}
      </form>
    </fieldset>
  )
}

export default CheckoutForm
