import { useContext, useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { TempAuthContext } from '../../Context/tempAuthContext';
import { DialogContext } from '../../Context/DialogContext';
import { LanguageContext } from '../../Context/language';
import { GoogleLogin } from '../../Utils/helpers/GoogleOauth2';
import { RegisterContext } from '../../Context/RegisterContext';

function SignIn() {
	const { language } = useContext(LanguageContext);
	const { signIn } = useContext(TempAuthContext);
	const { setSignupDialogOpen, setView } = useContext(DialogContext);
	const { createGoogleUser } = useContext(RegisterContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [userFeedback, setUserFeedback] = useState('');
	const [forgotPassword, setForgotPassword] = useState(false);
	const [confirmedReset, setConfirmedReset] = useState(false);
	const [tryingSignIn, setTryingSignIn] = useState(false);
	let locale = window.localStorage.getItem('locale');

	useEffect(() => {
		setError('');
	}, [email.toLowerCase(), password]);

	useEffect(() => {
		if (createGoogleUser) {
			setView('REGISTERFREE');
		}
	}, [createGoogleUser]);

	const handleSubmit = async (e) => {
		setError(null);
		e.preventDefault();

		setTryingSignIn(true);
		const signInRes = await signIn(email.toLowerCase(), password);
		if (!signInRes.accessToken) {
			setError('Wrong credentials');
		} else {
			setSignupDialogOpen(false);
			setError('');
		}
		setTryingSignIn(false);
	};

	async function requestResetToken(e) {
		e.preventDefault();
		try {
			const response = await fetch(
				`https://dev_api.staccs.com/api/user/forgot-password`,
				{
					method: 'POST',
					body: JSON.stringify({ username: email }),
					headers: { 'Content-Type': 'application/json' },
				}
			);
			if (response.status === 200) {
				setConfirmedReset(true);
				setUserFeedback(
					locale === 'se'
						? 'Om vi hittar ditt konto så skickar vi ett email med en länk. Klicka på länken för att återställa ditt lösenord.'
						: 'If we can find your account, we will send you an email with a reset link.'
				);
			}
		} catch (err) {
			console.error('user_forgot_password ERROR:', err);
		}
	}

	if (forgotPassword) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '1rem',
					height: '200px',
				}}
			>
				<h2
					style={{
						fontWeight: 'bold',
						fontSize: '20px',
						lineHeight: '25px',
						textAlign: 'center',
						marginBottom: '10px',
					}}
				>
					{language.resetPassword}
				</h2>
				<form
					onSubmit={(e) => requestResetToken(e)}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '1rem',
						maxWidth: '226px',
						width: '100%',
					}}
				>
					<TextField
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="outlined-basic"
						label="Email"
						variant="outlined"
						style={{ width: '100%' }}
						InputLabelProps={{ style: { fontSize: '12px' } }}
						InputProps={{ style: { fontSize: '12px' } }}
						type="email"
						autoFocus={true}
					/>
					<Button
						variant="contained"
						style={{
							maxWidth: '300px',
							width: '100%',
							height: '45px',
							backgroundColor: '#ffc65a',
							fontSize: '13px',
						}}
						disabled={!email || confirmedReset}
						type="submit"
					>
						{language.reset}
					</Button>
					{userFeedback && <p style={{ fontSize: '12px' }}>{userFeedback}</p>}
				</form>
			</div>
		);
	} else {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '1rem',
				}}
			>
				<h2
					style={{
						fontWeight: 'bold',
						fontSize: '20px',
						lineHeight: '25px',
						textAlign: 'center',
						marginBottom: '10px',
					}}
				>
					{language.signIn}
				</h2>
				<form
					onSubmit={(e) => handleSubmit(e)}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '1rem',
						maxWidth: '226px',
						width: '100%',
					}}
				>
					<TextField
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="outlined-basic"
						label="Email"
						variant="outlined"
						style={{ width: '100%', fontSize: '30px' }}
						InputLabelProps={{ style: { fontSize: '12px' } }}
						InputProps={{ style: { fontSize: '12px' } }}
						type="email"
						autoFocus={true}
					/>

					<TextField
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						className="outlined-basic"
						label="Password"
						variant="outlined"
						InputLabelProps={{ style: { fontSize: '12px' } }}
						InputProps={{ style: { fontSize: '12px' } }}
						style={{ width: '100%' }}
						type="password"
					/>
					{error && (
						<div
							className="registration__error-wrapper"
							style={{
								maxWidth: '300px',
								width: '100%',
								border: '1px solid red',
								borderRadius: '2px',
								padding: '10px',
							}}
						>
							<span
								style={{
									color: 'black',
								}}
							>
								{error}
							</span>
						</div>
					)}

					<Button
						variant="contained"
						style={{
							maxWidth: '300px',
							width: '100%',
							height: '45px',
							backgroundColor: '#ffc65a',
							fontSize: '13px',
							color: 'black',
						}}
						disabled={!email || !password}
						type="submit"
					>
						{tryingSignIn ? 'Signing in...' : `${language.signIn}`}
					</Button>
					<span className="registration__form-divider">or</span>

					<GoogleLogin />
					<p
						onClick={() => setForgotPassword(true)}
						style={{ cursor: 'pointer', fontSize: '12px', marginTop: '15px' }}
					>
						{language.forgotPassword}
					</p>
				</form>
			</div>
		);
	}
}

export default SignIn;
