import { useContext, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { LanguageContext } from '../../../../../Context/language';
import { RegisterContext } from '../../../../../Context/RegisterContext';
import CheckoutForm from '../../../../../Routes/Register/CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_kYGub1xSzIrHvx9ljFYCVnbB');

const UpgradePremiumStepTwo = ({ view, setView }) => {
	const { language } = useContext(LanguageContext);
	const { formData, paymentIntentClientSecret } = useContext(RegisterContext);
	const [completePay, setCompletePay] = useState(0);

	const onClickBack = () => {
		setView('UPGRADEPREMIUMSTEPONE');
	};

	if (completePay === 4) {
		setView('SIGNUPCOMPLETED');
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					fontWeight: 'bold',
					fontSize: '18px',
					textAlign: 'center',
					marginBottom: '10px',
				}}
			>
				{language.step} 3 / 3
			</div>
			<Elements
				stripe={stripePromise}
				options={{
					clientSecret: paymentIntentClientSecret,
					appearance: { theme: 'night' },
				}}
			>
				<div className="upgrade__pay-content">
					<h2
						className="section-heading--light"
						style={{ color: 'black', margin: '0' }}
					>
						{language.step3Headline}
					</h2>

					<p style={{ fontSize: '16px', textAlign: 'left' }}>
						{language.step3row1}
						{formData?.nickname === 'Yearly'
							? language.step3year
							: formData?.nickname === 'Monthly'
							? language.step3month
							: language.step3hours}
						{formData?.amount / 100 + ' ' + formData?.currency?.toUpperCase()}.
					</p>

					<CheckoutForm setStep={setCompletePay} />
					<div
						className="registration__back-btn"
						onClick={onClickBack}
						aria-label="Go back"
						role="button"
					>
						<div
							className="registration__back-btn-arrow"
							style={{ marginTop: '20px' }}
						></div>
					</div>
				</div>
			</Elements>
		</div>
	);
};

export default UpgradePremiumStepTwo;
