import './App.scss';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import { Outlet } from 'react-router-dom';
import SimpleDialog from './Components/discovery/content/signup-dialog/SignupDialog';

function App() {
	return (
		<div className="App">
			<Header />
			<Outlet />
			<Footer />
			<SimpleDialog />
		</div>
	);
}

export default App;
