import React, { useEffect, useState } from "react";
import axios from "axios";

export const ArtistCollabContext = React.createContext();

export const ArtistCollabProvider = (props) => {
  const [artistCollabs, setArtistCollabs] = useState([]);

  useEffect(() => {
    axios
      .get(`https://slamv2.herokuapp.com/api/artist-collabs?limit=20&page=1`)
      .then((res) => {
        setArtistCollabs(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <ArtistCollabContext.Provider value={{ artistCollabs, setArtistCollabs }}>
      {props.children}
    </ArtistCollabContext.Provider>
  );
};
