import { useContext, useEffect, useState } from 'react';
import { DialogContext } from '../../../../../Context/DialogContext';
import { Button } from '@mui/material';
import { RWebShare } from 'react-web-share';
import Loader from '../../../../Loader/Loader';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function GiftModal() {
	const { setSignupDialogOpen, giftLoading, gift } = useContext(DialogContext);
	const [deviceType, setDeviceType] = useState('web');
	const [userCopied, setUserCopied] = useState(false);

	useEffect(() => {
		if (navigator.userAgent.toLowerCase().match(/ipad|iphone/i)) {
			setDeviceType('iOS');
		} else if (navigator.userAgent.toLowerCase().match(/android/i)) {
			setDeviceType('android');
		}
	}, []);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(gift.url).then(() => {
			setUserCopied(true);
		});
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '2rem',
				}}
			>
				{giftLoading ? (
					<Loader />
				) : (
					<>
						<img
							src={`https://www.staccs.com/cdn-cgi/image/quality=70,h=400/${gift.img}`}
							alt="Gifted title image"
						/>
						<h2
							style={{
								fontWeight: 'bold',
								fontSize: '20px',
								lineHeight: '25px',
								textAlign: 'center',
								marginBottom: '10px',
							}}
						>
							Invite someone to watch {gift.title.name} with {gift.title.artist}
						</h2>
						<p
							style={{
								fontSize: '12px',
								maxWidth: '220px',
								textAlign: 'center',
							}}
						>
							You have the chance to invite people to watch 15 performances each
							month.
						</p>
						<p
							style={{
								fontSize: '12px',
								maxWidth: '220px',
								textAlign: 'center',
							}}
						>
							You have {gift.userRemainingQuota} invites remaining this month.
						</p>

						{deviceType !== 'web' ? (
							<RWebShare
								style={{ zIndex: 50000000000 }}
								data={{
									title: `Watch ${gift.title.name} with ${gift.title.artist}`,
									text: `Watch ${gift.title.name} with ${gift.title.artist} at Staccs 🔥`,
									url: gift.url,
									img: gift.img,
								}}
								onClick={() => setSignupDialogOpen(false)}
							>
								<Button
									variant="contained"
									style={{
										maxWidth: '300px',
										width: '100%',
										height: '45px',
										backgroundColor: '#ffc65a',
										fontSize: '13px',
										color: 'black',
									}}
								>
									Send invite
								</Button>
							</RWebShare>
						) : (
							<>
								<a>{gift.url}</a>
								<Button
									variant="contained"
									disabled={userCopied}
									style={{
										maxWidth: '300px',
										width: '100%',
										height: '45px',
										backgroundColor: userCopied ? '#60db59' : '#ffc65a',
										fontSize: '13px',
										color: 'black',
										display: 'flex',
										gap: '1rem',
									}}
									onClick={copyToClipboard}
								>
									<ContentCopyIcon />
									{userCopied ? 'Copied' : 'Copy Link'}
								</Button>
							</>
						)}
					</>
				)}
			</div>
		</>
	);
}

export default GiftModal;
