import React, { useState } from 'react';

export const HeaderContext = React.createContext(null);

export const HeaderProvider = (props) => {
	const [headerDisplayed, setHeaderDisplayed] = useState<
		'landing' | 'discover'
	>('landing');

	return (
		<HeaderContext.Provider value={{ headerDisplayed, setHeaderDisplayed }}>
			{props.children}
		</HeaderContext.Provider>
	);
};
